import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { HttpservicesService } from "../../services/httpservices.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ObservableService } from "src/app/observable.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: [null],
    password: [null],
  });

  public show: boolean = false;
  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpservicesService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {
    console.log('asdasdasdasd');
    
  }
  Login() {
    if (this.loginForm.invalid) {
      return false;
    }

    this.http.loginApi("/login", this.loginForm.value, false).subscribe(
      (res: any) => {
        localStorage.setItem("user", "true");
        window.localStorage.setItem("access_token", res.access_token);
        this.verify();
      },
      (err) => {
        if (err.status == 400) {
          this.toastr.error(err.error.message);
        }
      }
    );
  }
  verify() {
    if (this.loginForm.invalid) {
      return false;
    }

    this.http.loginApi("/login", this.loginForm.value, false).subscribe(
      (res: any) => {
        this.router.navigate(["/dashboard"]);
      },
      (err) => {
        if (err.status == 400) {
          this.toastr.error(err.error.message);
        }
      }
    );
  }
  showPassword() {
    this.show = !this.show;
  }

  loginFormData(data: any) {
  }
  // error observable
  async observe() {
    ObservableService.error.subscribe((res: any) => {
      if (res == true) {
        ObservableService.error_toast.subscribe((res: any) => {
          this.toastr.error(res.error.message);
        });
      }
    });
    ObservableService.error.next(false);
  }
}
