// import {Injectable} from "@angular/core";
// import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest} from "@angular/common/http";
// import {Observable} from "rxjs/Observable";

// @Injectable()
// export class AppHttpInterceptor implements HttpInterceptor {
//     constructor() {
//     }

//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         console.log(req, "resq");
//         return next.handle(req);
//     }
// }

import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(public router: Router, private toastr: ToastrService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error) => {
                console.log('error is intercept')
                console.log(error);
                if (error?.status === 401) {
                    window.localStorage.clear();
                    localStorage.removeItem("access_token");
                    this.router.navigateByUrl("/auth/login");
                }else{
                    this.toastr.error(error?.error?.message);
                }
                return throwError(error.message);
            })
        )
    }
}