import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthGuardServicesService } from "./auth-guard-services.service";

@Injectable({
  providedIn: "root",
})
@Injectable({
  providedIn: "root",
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private Authguardservice: AuthGuardServicesService,
    private router: Router
  ) {}
  canActivate(): boolean {
    if (!this.Authguardservice.gettoken()) {
      this.router.navigateByUrl("/auth/login");
    }
    return this.Authguardservice.gettoken();
  }
}
