import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	// MENUITEMS: Menu[] = [
	// 	{
	// 		title: 'Dashboard', icon: 'home', type: 'link', active: true, path: '/dashboard/default'
	// 	},
	// 	{
	// 		title: 'Manage Customers', icon: 'users', type: 'sub', active: false, children: [
	// 			{ path: '/manage-customers/allCustomers', title: 'View All Customers', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Manage Users', icon: 'users', type: 'sub', active: false, children: [
	// 			{ path: '/manage-users/allUsers', title: 'View All Users', type: 'link' },
	// 			{ path: '/manage-users/addUser', title: 'Add New User', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Manage Orders', type: 'sub', icon: 'shopping-bag', active: false, children: [
	// 			{ path: '/manage-orders/allOrders', title: 'View All Orders', type: 'link' },
	// 			{ path: '/manage-orders/pendingOrders', title: 'Pending Orders', type: 'link' },
	// 			{ path: '/manage-orders/bookedOrders', title: 'Booked Orders', type: 'link' },
	// 			{ path: '/manage-orders/cancelledOrders', title: 'Cancelled Orders', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Transactions', type: 'link', icon: 'dollar-sign', active: false, path: '/transaction-details'
	// 	},
	// ];

	// Array
	// items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
