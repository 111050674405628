import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class HttpservicesService {
  constructor(
    private http: HttpClient, // public general: GeneralService,
    private toastr: ToastrService // private cstmtostr: toasterFunc
  ) {}
  // loginApi
  loginApi(link, data, token) {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let headerT = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/json",
    };
    return this.http.post(
      environment.api.baseURL + link,
      JSON.stringify(data),
      {
        headers: !token ? header : headerT,
      }
    );
  }

  post(link, data, token) {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let headerT = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.localStorage.access_token,
      Accept: "application/json",
    };

    return this.http.post(
      environment.api.baseURL + link,
      JSON.stringify(data),
      {
        headers: !token ? header : headerT,
      }
    );
  }

  get(link, token) {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let headerT = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.localStorage.access_token,
      Accept: "application/json",
    };

    return this.http.get(
      environment.api.baseURL + link,
      {
        headers: !token ? header : headerT,
      }
    );
  }
}
