import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { content } from "./shared/routes/routes";
import { BehaviorSubject } from 'rxjs';
import { AuthGuardGuard } from './services/auth-guard.guard';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}
const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuardGuard],
    children: content,
  },
  { path: "**", redirectTo: "/auth/login" },
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true
})],
],
  exports: [RouterModule]
})

export class AppRoutingModule {
  MENUITEMS: Menu[] = [
    {
      title: 'Dashboard', icon: 'home', type: 'link', active: true, path: 'dashboard'
    },
    // {
    //   title: 'Currency', icon: 'dollar', type: 'link', active: false, path: '/dashboard/currency'
    // },
    {
      title: 'Manage Customers', icon: 'users', type: 'sub', active: false, children: [
        { path: '/manage-customers/all-customers', title: 'View All Customers', type: 'link' },
      ]
    },
    {
      title: 'Manage Users', icon: 'users', type: 'sub', active: false, children: [
        { path: '/manage-users/all-users', title: 'View All Users', type: 'link' },
        { path: '/manage-users/add-user', title: 'Add New User', type: 'link' },
      ]
    },
    {
      title: 'Manage Orders', type: 'sub', icon: 'shopping-bag', active: false, children: [
        { path: '/manage-orders/all-orders', title: 'View All Orders', type: 'link' },
        // { path: '/manage-orders/booked-orders', title: 'Booked Orders', type: 'link' },
        { path: '/manage-orders/pending-orders', title: 'Pending Orders', type: 'link' },
        { path: '/manage-orders/confirmed-orders', title: 'Confirmed Orders', type: 'link' },
        { path: '/manage-orders/cancelled-orders', title: 'Cancelled Orders', type: 'link' },
        { path: '/manage-orders/refund-orders', title: 'Refund Orders', type: 'link' },
        { path: '/manage-orders/junk-orders', title: 'Junk Orders', type: 'link' },
        { path: '/manage-orders/issued-orders', title: 'Issued Orders', type: 'link' },
      ]
    },
    {
      title: 'Hotels', type: 'sub', icon: 'home', active: false, children: [
        { path: '/manage-orders/all-hotels', title: 'View All Hotels', type: 'link' },
        { path: '/manage-orders/pending-hotels', title: 'Pending Hotels', type: 'link' },
        { path: '/manage-orders/cancelled-hotels', title: 'Cancelled Hotels', type: 'link' },
        { path: '/manage-orders/booked-hotels', title: 'Booked Hotels', type: 'link' },
      ]
    },
    {
      title: 'Transactions', type: 'link', icon: 'dollar-sign', active: false, path: '/transaction-details'
    },
    {
      title: 'Customize Trip', type: 'link', icon: 'lightbulb', active: false, path: '/dashboard/customize-trip'
    },
    {
      title: 'Contact Forms', type: 'link', icon: 'address-book', active: false, path: '/dashboard/contact-form'
    },
    {
      title: 'Career Forms', type: 'link', icon: 'address-book', active: false, path: '/dashboard/career-form'
    },
    
  ]; 
  
  // Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
