// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: {
    dynamic:'https://journeyplanner.store/expressflights_backend/public/api/v1',
    baseURL:'https://journeyplanner.store/expressflights_backend/public/api/v1'
    // dynamic:'http://localhost:8000/api/v1',
    // baseURL:'http://localhost:8000/api/v1'
  },
  // firebase: {
  //   apiKey: "Your Api Key",
  //   authDomain: "Your Auth Domain",
  //   databaseURL: "Your Database Url",
  //   projectId: "Your Project Id",
  //   storageBucket: "Your StorageBucket url",
  //   messagingSenderId: "Your Sender Id"
  // }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
