import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  constructor( private router: Router) {}

  ngOnInit() {}
  logout() {
    window.localStorage.clear();
    localStorage.removeItem("access_token");
    this.router.navigateByUrl("/auth/login");
  }
}
