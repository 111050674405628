import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ObservableService {
  constructor() {}
  public static error: Subject<any> = new Subject<any>();
  public static error_toast: Subject<any> = new Subject<any>();
}
