<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="https://i.ibb.co/qdGfz77/loho.png" alt="looginpage">
              <img class="img-fluid for-dark" src="https://i.ibb.co/qdGfz77/loho.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                 formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">
                  Email is required
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  Invalid Email
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" required=""
                  placeholder="*********" formControlName="password">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  Password is required
                </div>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary btn-block" [class.loader--text]=""
                  [disabled]="!loginForm.valid" 
                  (click)="Login()"
                  type="submit"><span>{{ 'Login' }}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>