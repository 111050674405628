import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'manage-customers',
    loadChildren: () => import('../../components/Manage-Customers/widgets.module').then(m => m.WidgetsModule)
  },
  {
    path: 'manage-orders',
    loadChildren: () => import('../../components/apps/Manage-Orders/e-commerce.module').then(m => m.ECommerceModule)
  },
  {
    path: 'manage-users',
    loadChildren: () => import('../../components/apps/Manage-Users/calender.module').then(m => m.CalenderModule)
  },
  {
    path: 'transaction-details',
    loadChildren: () => import('../../components/Transaction/cards.module').then(m => m.CardsModule)
  },
];
